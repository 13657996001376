import ToolTip from "components/General/ToolTip"
import { formatToNumericalDate } from "helpers/dateHelper"
import { Table, Button } from "react-bootstrap"
import { useNavigate } from "react-router"
import lessons from "shared/lessons"
import { AssignmentResponse, StudentResponse } from "shared/types/teacherTypes"
import EmptyTablePreview from "../General/EmptyTablePreview"
import { TeacherGradesState } from 'components/Lessons/Grading/GradeList/TeacherGrades'
import * as assignmentsModel from "shared/routes/assignments"
import styles from './teacher.module.scss'

export default function GradeBookTable({ assignments, students, startIndex, count } : {assignments: AssignmentResponse[], students: StudentResponse[], startIndex: number, count: number}) {
    const navigate = useNavigate()

    const calculateClassAverage = (assignment: AssignmentResponse) => {
        let totalClassAverage = 0
        let totalStudentSubmissions = 0

        for (const submission of assignment.submissions) {
            let currentAssignmentAverage = submission?.grade ?? 0
            if (submission?.topic || submission?.assessment || submission?.lesson?.grade) {
                totalStudentSubmissions += 1
            }
            totalClassAverage += currentAssignmentAverage
        }

        // Handling assignments that are overdue
        if (assignment.dueDate && new Date(assignment.dueDate) < new Date()) totalStudentSubmissions = students.length
        totalClassAverage /= totalStudentSubmissions

        if (totalClassAverage === 0) return 0
        if (isNaN(totalClassAverage)) return ""
        return `${totalClassAverage.toFixed(2)}%`
    }

    const calculateStudentGrade = (student: StudentResponse) => {
        let totalStudentSubmissions = 0
        let totalClassGrade = 0

        for (const assignment of assignments) {
            const studentSubmission = assignment.submissions.find(s => s.studentId === student.uuid)
            const currentAssignmentGrade = studentSubmission?.grade ?? 0

            if (studentSubmission?.topic || studentSubmission?.assessment || studentSubmission?.lesson?.grade) {
                totalStudentSubmissions += 1
            } else if (!studentSubmission?.assessment && !studentSubmission?.lesson?.grade && assignment.dueDate && new Date() > new Date(assignment.dueDate)) {
                totalStudentSubmissions += 1
            }

            totalClassGrade += currentAssignmentGrade
        }
        if (totalStudentSubmissions === 0) return ""

        const studentClassGradePercentage = totalClassGrade / totalStudentSubmissions
        if (isNaN(studentClassGradePercentage)) return ""

        let gradeLetter: string
        if (studentClassGradePercentage >= 90 && studentClassGradePercentage <= 100) gradeLetter = 'A'
        else if (studentClassGradePercentage >= 80 && studentClassGradePercentage < 90) gradeLetter = 'B'
        else if (studentClassGradePercentage >= 70 && studentClassGradePercentage < 80) gradeLetter = 'C'
        else if (studentClassGradePercentage >= 60 && studentClassGradePercentage < 70) gradeLetter = 'D'
        else if (studentClassGradePercentage >= 0 && studentClassGradePercentage < 60) gradeLetter = 'F'

        return `${studentClassGradePercentage.toFixed(2)}% ${gradeLetter}`
    }

    const formatStudentAssignmentGrade = (student: StudentResponse, assignment: AssignmentResponse) => {
        const submission = student.submissions.find(s => s.studentClassAssignmentId === assignment.id)
        let studentGrade = submission?.grade ?? 0

        // Check for an over due assignment that hasn't been submitted
        if (assignment.dueDate && !submission?.assessment && !submission?.lesson?.grade && new Date() > new Date(assignment.dueDate)) return ["Late", 0]

        if (!submission?.topic && !submission?.assessment && !submission?.lesson?.grade) return ["Not Submitted", ""]
        if (studentGrade === 0) return ["Submitted", 0]

        return ["Submitted", `${(studentGrade).toFixed(2)}%`]
    }

    const handleAssignmentClick = async (assignment) => {
        if (assignment.lessonVariety === "Guided") {
            if (assignment.lessonId) {
                var lesson = await lessons.findById(assignment.lessonId)
            }
            const TeacherGradesState: TeacherGradesState = {
                assignment: assignment,
                lesson: lesson
            }
            navigate("/dashboard/teacher/assignments/grading", {
                state: TeacherGradesState
            })
        } else if (assignment.lessonVariety === "Pure Inquiry") {
            const TeacherGradesState: TeacherGradesState = {
                assignment: assignment
            }
            navigate("/dashboard/teacher/assignments/grading", {
                state: TeacherGradesState
            })
        }
    }

    const navigateToSubmission = async (student, assignment) => {
        const submissionsResponse = await assignmentsModel.getGrades({ assignmentId: assignment.id })
        const currentSubmission = student.submissions.find(s => s.studentClassAssignmentId === assignment.id)
        const currentIndex = submissionsResponse.data.findIndex(res => res.submission.id === currentSubmission.id)

        navigate("/dashboard/teacher/assignments/grading/submission", {
            state: {
                submissions: submissionsResponse.data,
                assignment: assignment,
                currentIndex
            }
        })
    }

    const onClickAddAssignments = () => {
        // reset selectedGradeInit and initStandardId filters so that grade level and standard id from current class would be pre-selected in lesson search page
        localStorage.removeItem("selectedGrades")
        localStorage.removeItem("selectedStandardId")
        navigate("/dashboard/teacher/assignments/search")
    }

    return (
        <>
            {assignments.length !== 0 ? <Table responsive className='mb-5 table-striped' style={{ verticalAlign: "middle", position: "relative", overflowY: "auto" }}>
                <thead className="sticky-top" style={{ verticalAlign: "middle" }}>
                    <tr>
                        <th className="border-end"></th>
                        {assignments.map((assignment, index) => {
                            if (index >= startIndex && index < startIndex + count) {
                                return (
                                    <th
                                        key={assignment.id}
                                        className="text-center align-middle border-end"
                                        onClick={() => handleAssignmentClick(assignment)}
                                        role="button"
                                    >
                                        <div>
                                            <p className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>
                                                {assignment.title}
                                            </p>
                                        </div>
                                    </th>
                                )
                            } else {
                                return null
                            }
                        })}
                        <th className="text-center py-3">Student Class Grade</th>
                    </tr>
                    <tr className='border-bottom'>
                        <td className='text-center py-3 px-4' style={{ fontWeight: "bold" }}>Due Date</td>
                        {assignments.map((assignment, index) => {
                            if (index >= startIndex && index < startIndex + count) {
                                return (
                                    <td key={assignment.id} className="text-center py-auto">
                                        {assignment.dueDate ? formatToNumericalDate(assignment.dueDate) : "N/A"}
                                    </td>
                                )
                            } else {
                                return null
                            }
                        })}
                        <th></th>
                    </tr>
                </thead>
                <tbody style={{ verticalAlign: "middle" }}>
                    {students.map(student => (
                        <tr key={student.uuid}>
                            <ToolTip title="Student Records">
                                <td
                                    className={`${styles.studentEntry} text-center py-3 border-end`}
                                    role="button"
                                    onClick={() => {
                                        window.location.href = `/student/records/${encodeURIComponent(student.uuid)}/${encodeURIComponent(student.name)}`
                                    }}
                                >
                                    {student.name}
                                </td>
                            </ToolTip>
                            {assignments.map((assignment, index) => {
                                if (index >= startIndex && index < startIndex + count) {
                                    const [assignemntStatus, studentGrade] = formatStudentAssignmentGrade(student, assignment)
                                    if (assignemntStatus === "Late") {
                                        return (
                                            <ToolTip title={assignemntStatus === "Late" && "Late assignment"}>
                                                <td key={assignment.id} style={{ backgroundColor: "#fec1c1" }} className={`text-center py-3`}>
                                                    {studentGrade}
                                                </td>
                                            </ToolTip>
                                        )
                                    } else {
                                        return (
                                            <td key={assignment.id} className={`${assignemntStatus === "Submitted" && styles.submission} text-center py-3`} onClick={assignemntStatus === "Submitted" ? () => navigateToSubmission(student, assignment) : null}>
                                                {studentGrade}
                                            </td>
                                        )
                                    }
                                } else {
                                    return null
                                }
                            })}
                            <td className="text-center py-3" style={{ fontWeight: "bold" }}>{calculateStudentGrade(student)}</td>
                        </tr>
                    ))}
                    <tr className='border-top'>
                        <td className="text-center py-3 px-4" style={{ fontWeight: "bold" }}>Class Average</td>
                        {assignments.map((assignment, index) => {
                            if (index >= startIndex && index < startIndex + count) {
                                return (
                                    <td key={assignment.id} className="text-center" style={{ fontWeight: "bold" }}>
                                        {calculateClassAverage(assignment)}
                                    </td>
                                )
                            } else {
                                return null
                            }
                        })}
                        <td></td>
                    </tr>
                </tbody>
            </Table>
                :
                <EmptyTablePreview
                    title="Create Assignments"
                    description="When you add an assignment, it will be displayed at the top of the gradebook."
                >
                    <Button
                        variant="theme"
                        onClick={onClickAddAssignments}
                        data-testid="empty-add-assignment"
                    >
                        + Add Assignments
                    </Button>
                </EmptyTablePreview>

            }
        </>
    )
}

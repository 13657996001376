import Label from "components/General/Label"
import { Dispatch, SetStateAction, useState } from "react"
import { Button, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import { AssessmentSubmissionResponse, LessonSubmissionResponse, MasteryTypes, TopicSubmissionResponse } from "shared/types/moduleTypes"
import GradeInput from "../components/GradeInput"
import { SubmissionStandardsMastery, TeacherAssignmentResponse } from "shared/types/teacherTypes"
import modules from "shared/routes/moduleRoutes"
import pureInquiry from "shared/routes/simulations/pureInquiry"
import styles from "../submissionTeacher.module.scss"
import ToolTip from "components/General/ToolTip"
import StandardsMasterySelection from "../components/StandardsMasterySelection"
import { useModel } from "@stem-sims/nexus"
import { findTopicStandards } from "shared/topics"
import React from "react"
import { formatGrade } from "shared/helpers/formatGrade"

interface Props {
    /**
     * Props for topic assignment sidebar
     */
    topicSubmission?: TopicSubmissionResponse
    setTopicSubmission?: Dispatch<SetStateAction<TopicSubmissionResponse>>
    studentTopicId?: string
    submissionStandardsMastery: SubmissionStandardsMastery[]
    recalTopicGrade?: () => void

    lessonSubmission: LessonSubmissionResponse
    assessmentSubmission: AssessmentSubmissionResponse
    setLessonSubmission: Dispatch<SetStateAction<LessonSubmissionResponse>>
    lesson: any
    assignment: TeacherAssignmentResponse
    studentLessonId: string
    activeClass?: {
        standardId: string
    }
    submissionId: string
}

const TeacherLessonSidebar = ({ topicSubmission, setTopicSubmission, studentTopicId, lessonSubmission, assessmentSubmission, setLessonSubmission, lesson, assignment, studentLessonId, activeClass, submissionStandardsMastery, submissionId, recalTopicGrade } : Props) => {
    const [submitting, setSubmitting] = useState(false)
    const [selectedStandards, setSelectedStandards] = useState<SubmissionStandardsMastery[]>([])

    const { response: standardsResponse } = useModel({
        model: findTopicStandards,
        props: {
            topicId: assignment.topicId,
            lessonId: assignment.lessonId,
            standardId: activeClass?.standardId,
        }
    })

    const standards = standardsResponse?.standards ?? []

    const publishLessonTopicGrade = async () => {
        let promises = []

        const gradingTopic = !!(topicSubmission && studentTopicId)
        const gradingLesson = !!(lessonSubmission && lesson)
        const gradingPureInquiry = !!(assignment.lessonVariety === "Pure Inquiry" && lessonSubmission)
        const gradingStandards = selectedStandards.length > 0

        if (gradingStandards) {
            selectedStandards.forEach((standard) => {
                const standardsGradePromise = modules.publishStandardsGrade({
                    submissionId: submissionId,
                    subtopicId: standard.subtopicId,
                    mastery: standard.mastery
                })
                promises.push(standardsGradePromise)
            })
        }

        if (gradingTopic) {
            const topicGradePromise = modules.publishTopicGrade({
                studentTopicId: studentTopicId,
                comment: topicSubmission?.comment ?? undefined,
                grade: topicSubmission?.grade,
                isFinalGrade: true,
            })
            promises.push(topicGradePromise)
        }

        // if there is no lesson submission, lesson grade will not be published
        if (gradingLesson) {
            const lessonGradePromise = modules.publishLessonGrade({
                moduleName: lesson.module,
                lessonNumber: lesson?.number.toString(),
                lessonType: lesson.type,
                studentLessonId,
                grade: lessonSubmission.grade,
                isFinalGrade: true,
                comment: lessonSubmission.labComment ?? undefined,
            })
            promises.push(lessonGradePromise)
        }

        if (gradingPureInquiry) {
            const pureInquiryGradePromise = pureInquiry.putGrade({
                pureInquiryId: studentLessonId,
                comment: lessonSubmission.labComment ?? undefined,
                grade: lessonSubmission.grade,
            })
            promises.push(pureInquiryGradePromise)
        }

        if (promises.length !== 0) {
            return Promise.all(promises)
                .then(() => {
                    if (gradingTopic && gradingLesson) {
                        toast.success("Published grades.")
                    } else if (gradingTopic && !gradingLesson) { 
                        toast.success("Published topic grade.")
                    } else if (!gradingTopic && gradingLesson) {
                        toast.success("Published lesson grade.")
                    } else if (gradingPureInquiry) {
                        toast.success("Published pure inquiry grade.")
                    }
                }).catch(() => {
                    toast.error("Failed to publish grade, please try again.")
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
        return setSubmitting(false)
    }

    const updateTopicGrade = (grade: number) => {
        const newTopic = { ...topicSubmission }
        newTopic.grade = isNaN(grade) ? newTopic.grade : grade
        setTopicSubmission(newTopic)
    }

    const updateLessonGrade = async (grade: number) => {
        const newLesson = { ...lessonSubmission }
        newLesson.grade = isNaN(grade) ? newLesson.grade : grade
        newLesson.overallGrade = isNaN(grade) ? newLesson.overallGrade : !!assessmentSubmission ? ((grade * 0.7) + (assessmentSubmission.grade * 0.3)) : grade
        setLessonSubmission(newLesson)

        const gradingTopic = !!topicSubmission && !!studentTopicId
        if (gradingTopic) {
            await modules.publishLessonGrade({
                moduleName: lesson.module,
                lessonNumber: lesson?.number.toString(),
                lessonType: lesson.type,
                studentLessonId,
                grade: newLesson.grade,
                isFinalGrade: false,
                comment: lessonSubmission.labComment ?? undefined,
            })
            recalTopicGrade?.()
        }
    }

    const updateComment = (comment: string) => {
        if (topicSubmission) {
            const newTopic = { ...topicSubmission }
            newTopic.comment = comment
            setTopicSubmission(newTopic)
        } else {
            const newLesson = { ...lessonSubmission }
            newLesson.labComment = comment
            setLessonSubmission(newLesson)
        }
    }

    const timeTaken = (topicSubmission || lessonSubmission) && Math.ceil((topicSubmission ?
        Math.ceil((
            new Date(topicSubmission.completed).getTime()
            -
            new Date(topicSubmission.started)
                .getTime()
        )) :
        Math.ceil((
            new Date(lessonSubmission.completed).getTime()
            -
            new Date(lessonSubmission.started)
                .getTime()
        ))) / (1000 * 60))

    return <>
        <div className="text-center">
            <Button
                variant="theme"
                size="lg"
                className="mb-3 mt-3 fw-bold"
                disabled={submitting}
                onClick={() => {
                    setSubmitting(true)
                    publishLessonTopicGrade()
                }}
            >
                {!submitting && <span className={styles.submitGradeBtnText}>{topicSubmission ? `Submit Grades` : `Submit Lesson Grade`}</span>}
                {submitting && <span className={styles.submitGradeBtnText}>Submitting....</span>}
            </Button>
        </div>
        {timeTaken && <Label title="Time Taken">
            {timeTaken} minute{timeTaken === 1 ? "" : "s"}
        </Label>}
        {topicSubmission &&
            <Label title="Overall Grade">
            <GradeInput
                className={styles.overallGradeInput}
                value={topicSubmission.grade}
                onChange={(grade) => {
                    updateTopicGrade(grade)
                }}
            />
            %
        </Label>
        }
        {lessonSubmission && 
            <div>
                {!topicSubmission && <div>
                    <Label title="Overall Grade">
                        {formatGrade((lessonSubmission.overallGrade ?? 0).toString())}
                    </Label>
                </div>}
                <div>
                    <Label title="Lesson Grade">
                        <GradeInput
                            className={styles.overallGradeInput}
                            value={lessonSubmission.grade}
                            onChange={(grade) => {
                                updateLessonGrade(grade)
                            }}
                        />
                        %
                    </Label>
                </div>
                {assessmentSubmission && <div>
                    <Label title="Assessment Grade">
                        {formatGrade((assessmentSubmission.grade ?? 0).toString())}
                    </Label>
                </div>}
            </div>}
        <Label title="Points">
            {(lessonSubmission?.points ?? 0) + (topicSubmission?.points ?? 0)}/{(lessonSubmission?.totalPoints ?? 0) + (topicSubmission?.totalPoints ?? 0)}
        </Label>
        {standards.length > 0 && <>
            <Row className="mt-4 mb-2">
                <strong>Standards Mastery</strong>
            </Row>
            <Row>
                {standards.map((standard) => <React.Fragment key={standard.subtopicId}>
                    <ToolTip title={standard.description}>
                        <p className="pt-3">{standard.subtopicCode}</p>
                    </ToolTip>
                    <StandardsMasterySelection
                        initialValue={submissionStandardsMastery?.find((s) => s.subtopicId === standard.subtopicId)?.mastery ?? null}
                        subtopicId={standard.subtopicId}
                        onChange={(newMastery: MasteryTypes | null) => {
                            let newStandards = [...selectedStandards]
                            const existingStandardIndex = newStandards.findIndex((newStandard) => {
                                return newStandard.subtopicId === standard.subtopicId
                            })

                            if (existingStandardIndex !== -1) {
                                if (newMastery !== null) {
                                    newStandards[existingStandardIndex].mastery = newMastery
                                }
                                else {
                                    //Remove the standard from list
                                    newStandards = [
                                        ...newStandards.slice(0, existingStandardIndex),
                                        ...newStandards.slice(existingStandardIndex + 1)
                                    ]
                                }
                            }
                            else {
                                newStandards.push({
                                    subtopicId: standard.subtopicId,
                                    mastery: newMastery,
                                    subtopicCode: standard.subtopicCode,
                                    description: standard.description
                                })
                            }
                            setSelectedStandards(newStandards)
                        }}
                    />
                </React.Fragment>)}
            </Row>
        </>}
        <hr />
        <i className="far fa-comment-dots pe-2 pb-2" />
        <strong>Comments</strong>
        <br />
        <textarea
            className={`${styles.commentTextbox} w-100 p-2`}
            defaultValue={topicSubmission?.comment ?? lessonSubmission?.labComment ?? ""}
            onChange={(e) => {
                updateComment(e.currentTarget.value)
            }}
        />
    </>
}

export default TeacherLessonSidebar

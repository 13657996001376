import { AssignmentSubmissionResponse, StudentAssignmentResponse } from "shared/types/studentTypes"
import Table from "react-bootstrap/Table"
import { useLocation, useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { LessonResponse } from "shared/lessons"
import { SubmissionStudentState } from "pages/Dashboard/Student/Grades/Submission/SubmissionStudent"
import { showGrade } from "./StudentGrades"

/**
 * Location state properties that this page is expecting when being redirected here
 */
export class StudentSubmissionsState {
    constructor() {
        this.previousPage = ".."
        this.currentIndex = 1
        this.lesson = null
    }
    assignment: StudentAssignmentResponse
    studentId: string
    lesson?: LessonResponse
    previousPage?: string
    currentIndex?: number
}

export default function Submissions() {
    const location = useLocation()
    const navigate = useNavigate()
    const state = location.state as StudentSubmissionsState

    const navigateToSubmission = (submission: AssignmentSubmissionResponse, index) => {
        if (submission.dateSubmitted) {
            navigate("/dashboard/student/grades/submission", {
                state: {
                    currentIndex: index,
                    assignment: state.assignment,
                    submission: submission,
                    studentId: state.assignment.studentId,
                    userType: "Student",
                    previousPage: "/dashboard/student/grades/submissions"
                } as SubmissionStudentState
            })
        }
    }

    return <>
        <h1 className="h2 text-start mt-3">{state.assignment?.title ?? ""} Submissions</h1>
        <div className="text-start mb-3">
            <Link className="text-decoration-none" to={state.previousPage ?? "/dashboard/student/grades"}>
                <i className="fas fa-arrow-left pe-2" />
                <span>Return to Grade List</span>
            </Link>
        </div>
        <Table hover>
            <thead>
                <tr>
                    <th>Overall Grade</th>
                    <th>Topic Grade</th>
                    <th>Lesson Grade</th>
                    <th>Assessment Grade</th>
                    <th>Completed</th>
                </tr>
            </thead>
            <tbody>
                {state.assignment.submissions.map((submission, index) => (<>
                        <tr role={submission.dateSubmitted && "button"} onClick={() => navigateToSubmission(submission, index)}>
                            <td>{showGrade({gradeType: "overall", submission: submission, assignment: state.assignment, showRecentLabel: true })}</td>
                            <td>{showGrade({gradeType: "topic", submission: submission, assignment: state.assignment })}</td>
                            <td>{showGrade({gradeType: "lesson", submission: submission, assignment: state.assignment })}</td>
                            <td>{showGrade({gradeType: "assessment", submission: submission, assignment: state.assignment })}</td>
                            <td>{submission.dateSubmitted ? new Intl.DateTimeFormat().format(new Date(submission.dateSubmitted)) : "Not Completed"}</td>
                        </tr>
                    </>
                ))}
            </tbody>
        </Table>
    </>
}

export type CurriculumBook = {
    id: string;
    name: string;
    description: string;
    image?: string;
    visible: boolean;
    exclusive: boolean;
}

export type CurriculumUnit = {
    id: string;
    name: string;
    number: number;
    description: string;
    topics?: CurriculumTopic[];
}

export type CurriculumTopic = {
    id: string;
    name: string;
    number: number;
    description: string;
    image?: string;
    backgroundColor?: string;
    accentColor?: string;
    sections?: CurriculumSection[];
}

export interface CurriculumTopicWithGradable extends CurriculumTopic {
    sections: CurriculumSectionWithGradable[]
}

export const CurriculumSectionTypes = ["HTML", "Lesson", "Assessment", "Questions"] as const

export type CurriculumSectionType = (typeof CurriculumSectionTypes)[number]

export type CurriculumSection = {
    id: string;
    header?: string;
    sectionText?: string;
    type: CurriculumSectionType;
    graded: boolean
    lessonId?: string;
    assessmentId?: string;
    content?: CurriculumSectionContent[];
    questions?: CurriculumQuestion[];
    sortOrder: number;
}

export interface CurriculumSectionWithGradable extends CurriculumSection {
    isGradable?: boolean
}

export type CurriculumSectionContent = {
    id: string;
    sectionId: string;
    sortOrder: number;
    content: string;
    teacherOnly?: boolean;
}

export type CurriculumQuestionType = "MultipleChoice" | "Text" | "Drawing"

export type CurriculumQuestion = {
    id: string;
    sortOrder: number;
    question: string;
    type: CurriculumQuestionType;
    correctAnswer?: string;
    answers?: CurriculumAnswer[];
    questionImageId?: string;
    questionImageUrl?: string;
    exampleImageId?: string;
    exampleImageUrl?: string;
}

export type CurriculumAnswer = {
    id: string;
    letter?: string;
    answer: string;
    explanation?: string;
}

export type TopicStudentAnswers = {
    [key: string]: { topicQuestionId: string, answer: string, drawingResponse: string, questionType: CurriculumQuestionType, isCheckbox?: boolean }
}

import React from "react"
import roundToFixed from "shared/helpers/roundToFixed"

export default function GradeInput({ className, value, onChange }: { className: string, value: number, onChange: (value: number) => void }) {
    const [grade, setGrade] = React.useState(String(value))

    const cleanGrade = (newGrade: string) => {
        const max = 100

        if (newGrade === "" || isNaN(parseFloat(newGrade))) {
            newGrade = "0"
        }
        if (newGrade.trim().endsWith(".")) {
            newGrade = newGrade.replace(".", "")
        }
        if (parseFloat(newGrade) > max) {
            newGrade = String(max)
        }

        newGrade = roundToFixed(newGrade, 2)
        setGrade(newGrade)
        return newGrade
    }

    React.useEffect(() => {
        //Clean the initial grade
        cleanGrade(String(value))
    }, [value])

    return (
        <input
            className={className}
            type="text"
            value={grade}
            onChange={ e => {
                //only 0-9 and decimal points are accepted
                let val = e.target.value.replace(/[^0-9|.]/gi, "")
                //Ensure that there is no more than one decimal
                //Credit: https://stackoverflow.com/a/8141041/8082502
                val = val.replace(/\./,"#").replace(/\./g,"").replace(/#/,".")
                const strVal = isNaN(parseFloat(val)) ? "" : val
                setGrade(strVal)
            }}
            onBlur={() => {
                const newGrade = parseFloat(cleanGrade(grade))
                onChange(newGrade)
            }}
        />
    )
}

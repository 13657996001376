import { AxiosError } from "axios"

function isAxiosError(error: unknown): error is AxiosError {
    return typeof error === "object" && Object.prototype.hasOwnProperty.call(error, "response")
}

export function permissionResponseIntercept(error: unknown) {
    if (isAxiosError(error)) {
        if (error.response?.status === 401 && error.response.data?.message === "Invalid permissions.") {
            window.location.href = "/"
        } else {
            return error
        }
    } else {
        return error
    }
}

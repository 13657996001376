import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import BeatLoader from 'react-spinners/BeatLoader'
import { ClassResponse, StudentResponse } from '../../../shared/types/teacherTypes'
import * as teacher from '../../../shared/routes/teacher'
import { useModel } from '@stem-sims/nexus'
import GradeBookTable from './GradeBookTable'
import { Col, Form, Pagination, Row } from 'react-bootstrap'

interface GradeBookProps {
    activeClass: ClassResponse
    refreshClasses: () => void
}

export default function GradeBook({ activeClass, refreshClasses }: GradeBookProps) {
    const [students, setStudents] = useState<StudentResponse[]>([])
    const [loadingStudents, setLoading] = useState(false)
    const [startIndex, setStartIndex] = useState(0)
    const [count, setCount] = useState(5)

    const sort = React.useRef<"first" | "last">(localStorage.getItem("studentSortOrder") as "first" | "last" ?? "last")

    const studentSort = (s1: StudentResponse, s2: StudentResponse) => {
        const firstStudentSort = s1.name.split(" ").at(sort.current === "first" ? 0 : -1)
        const secondStudentSort = s2.name.split(" ").at(sort.current === "first" ? 0 : -1)
        return firstStudentSort.localeCompare(secondStudentSort)
    }

    const refreshStudents = () => {
        setLoading(true)
        return teacher.getStudents({ classID: activeClass.id }).then((teacherStudents) => {
            setStudents(teacherStudents.sort(studentSort))
            setLoading(false)
        }).catch(() => {
            //Does the class still exist?
            toast.dismiss()
            toast.error("There was an issue getting your students.")
            refreshClasses()
        })
    }

    function assignmentError(err) {
        toast.error(err.response?.data?.message ?? "There has been an error loading the assignments. Please try again.")
        refreshClasses()
    }

    const { response: assignments, loading, refreshModel: reloadAssignments } = useModel({
        model: teacher.getAssignments,
        onError: assignmentError,
        props: { classID: activeClass.id }
    })

    useEffect(() => {
        reloadAssignments()
    },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [activeClass.id])

    useEffect(() => {
        void refreshStudents()
    },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [activeClass.id])

    useEffect(() => {
        setStartIndex(0)
    },
        [assignments])

    if (loading || loadingStudents) {
        return (
            <div className="d-table h-100 mx-auto">
                <div className="d-table-cell text-center align-middle">
                    <BeatLoader size={15} />
                </div>
            </div>
        )
    }

    return (
        <div className="mx-2">
            <Row className="align-items-center">
                <Col>
                    <h1 className="h2 text-start mt-3">Class Grades</h1>
                </Col>
                <Col className="vertical-center text-end">
                    <div className="d-inline-block">
                        <Form.Select
                            aria-label="Results per page"
                            className="d-inline-block w-auto me-2"
                            onChange={(e) => setCount(Number(e.target.value))}
                            value={count}
                        >
                            <option value={5}>5 per page</option>
                            <option value={10}>10 per page</option>
                            <option value={20}>20 per page</option>
                    </Form.Select>
                    </div>
                    <div className="d-inline-block">
                        <Pagination className={`d-flex justify-content-end my-0 mx-0 fade ${assignments.length > count && "show"}`}>
                            {assignments.length > count * 2 && 
                            <Pagination.First onClick={() => setStartIndex(0)} disabled={startIndex === 0} />
                            }
                            <Pagination.Prev onClick={() => setStartIndex(startIndex - count)} disabled={startIndex === 0} />
                            {Array.from(Array(Math.ceil(assignments.length / count))).map((_,pageNum) => 
                                ((Math.abs((startIndex/count)-pageNum) < 2) && 
                                    <Pagination.Item onClick={() => setStartIndex(pageNum * count)} className={`${((pageNum) === Math.floor(startIndex/count)) && "active"}`} key={"page-" + pageNum}>{pageNum+1}</Pagination.Item>
                                )
                            )}
                            <Pagination.Next onClick={() => setStartIndex(startIndex + count)} disabled={startIndex + count >= assignments.length} />
                            {assignments.length > count * 2 && 
                                <Pagination.Last onClick={() => setStartIndex(Math.ceil(assignments.length / count) * count - count)} disabled={startIndex + count >= assignments.length} />
                            }
                        </Pagination>
                    </div>
                </Col>
            </Row>
            <GradeBookTable assignments={assignments} students={students} startIndex={startIndex} count={count} />
        </div>
    )
}

import axios from "axios"

export type OrganizationType = "school"

export type RequestQuoteEmailProps = {
    organizationName: string,
    contactName: string,
    contactEmail: string,
    organizationType: OrganizationType,
    state: string,
    studentCount: string,
    moreDetails: string,
    username?: string,
}

export async function requestQuoteEmail(props: RequestQuoteEmailProps) {
    const response = await axios.post("/api/request-quote", props)
    return response.data
}

const contactUs = {
    requestQuoteEmail,
}

export default contactUs
